import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { rhythm } from '~utils/typography'

const Wrapper = styled.div`
  text-align: center;
  margin: ${rhythm(1.5)};
`

export const Facebook = () => {
  return (
    <Wrapper>
      <Link
        rel='noopener noreferrer'
        target='_blank'
        href='https://www.facebook.com/kwietniewoPL'
      >
        Znajdziesz nas na Facebooku
      </Link>
    </Wrapper>
  )
}
