import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '~components/layout'
import SEO from '~components/seo'
import { rhythm, scale } from '~utils/typography'
import { Facebook } from '~components/Facebook'

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { moreArticles } = pageContext
  // console.log({ moreArticles })
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        coverImage={post.frontmatter.hero}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          ></p>
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        <footer>
          <Facebook />

          <small>
            <i>
              <p>
                Wszelkie materiały (treści, teksty, ilustracje, zdjęcia itp.)
                przedstawione na stronie kwietniewo.pl są objęte prawem
                autorskim i podlegają ochronie na mocy „Ustawy o prawie
                autorskim i prawach pokrewnych” z dnia 4 lutego 1994 r. (tekst
                ujednolicony: Dz.U. 2006 nr 90 poz. 631).
              </p>
              <p>
                Kopiowanie, przetwarzanie, rozpowszechnianie tych materiałów w
                całości lub w części bez zgody autora jest zabronione.
              </p>
            </i>
          </small>
        </footer>
      </article>
      <nav>
        <h4>Więcej Artykułów</h4>
        <ul
          style={{
            listStyle: `none`,
            padding: 0,
            fontSize: '0.8rem',
          }}
        >
          {moreArticles.map(article => (
            <li key={article.fields.slug}>
              <Link to={article.fields.slug} style={{ boxShadow: 'none' }}>
                → {article.frontmatter.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        description
        hero
      }
    }
  }
`
